import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../layouts/index"
import sanityConfig from "../../sanity"
import { GatsbyImage } from "gatsby-plugin-image"
import * as css from "./product-page.module.css"
// import BlockContent from "@sanity/block-content-to-react"
// import useSiteMetadata from "../components/use-site-metadata"
import { FormattedMessage } from "react-intl"

export const query = graphql`
  query ProductQuery($id: String!) {
    product: sanityProducts(id: { eq: $id }) {
      id
      title {
        en
        nb
      }
      description {
        en
        nb
      }
      images {
        _key
        asset {
          _id
          gatsbyImageData
        }
      }
      slug {
        en {
          current
        }
        nb {
          current
        }
      }
      prices {
        _key
        _type
        name {
          en
          nb
        }
        price {
          en
          nb
        }
      }
    }
  }
`

const ProductPageTemplate = props => {
  const { data, errors, pageContext, location } = props
  const { lang } = pageContext
  const product = data && data.product
  // const { sanitySiteSettings } = useSiteMetadata()

  if (errors) {
    return (
      <Layout>
        <SEO title="GraphQL Error" />
        Error
      </Layout>
    )
  }

  const { title, images, description, prices } = product

  return (
    <Layout {...props}>
      <SEO title={title[lang] || "Untitled"} />
      <article className={css.productPage}>
        <GatsbyImage
          image={images[0].asset.gatsbyImageData}
          className={css.image}
          alt={title[lang] || ""}
        />
        <h1>{title[lang]}</h1>
        <p>{description[lang]}</p>

        <h2>
          <FormattedMessage id="Prices" />
        </h2>
        <ul>
          {prices.map(price => {
            return (
              <li key={price._key}>
                <strong>NOK {price.price[lang] || price.price.en},-</strong>{" "}
                {price.name[lang] || price.name.en}
              </li>
            )
          })}
        </ul>
        {/* <BlockContent blocks={sanitySiteSettings._rawOrderingInstructions} /> */}
      </article>
    </Layout>
  )
}

export default ProductPageTemplate
